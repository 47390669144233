<template>
  <div>
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column">
        <div style="font-size: 28px; margin-top: 20px;">关于我</div>
        <ul style="color: #494949;">
          <li>一个不<del>喜欢</del>懂科研的准研究生，打过ACM比赛，CF灰名水平，喜欢写代码</li>
          <li>对计算机的很多技术都很感兴趣，除了人工智能，是激进的<del>反AI者</del>弱人工智能支持者</li>
          <li>数学不太行，英文也不太行，语文更不行</li>
          <li>我的github: <a href="https://www.github.com/Zeng1998">https://www.github.com/Zeng1998</a></li>
          <li>我的邮箱: <a href="mailto:1129142694@qq.com">1129142694@qq.com</a></li>
        </ul>
        <div style="font-size: 28px; margin-top: 20px;">关于这个博客</div>
        <ul style="color: #494949;">
          <li>前端用了Vue2.0和Buefy，暂时没有后端，数据是写了个小脚本直接从md生成</li>
          <li>在数据量很小的情况下，通过axios读取再filter速度还是很快的，<del>又不是不能用</del></li>
<!--          <li>博客分为几个分类，其中"杂七杂八"主要是一些编程过程中遇到的<b>小</b>问题及解决方案;-->
<!--            偶尔做做AtC，CF，LC，GCJ，KS等的一些题解会放在"题解"分类下;</li>-->
        </ul>
<!--        <h1>TODOList</h1>-->
<!--        <ul style="color: #494949;">-->
<!--          <li>加强搜索功能</li>-->
<!--          <li>增加留言板页面</li>-->
<!--          <li>增加书架页面</li>-->
<!--          <li>优化代码</li>-->
<!--          <li>多写博客...</li>-->
<!--          <li>增加加密文章功能(相当于私密笔记的个人展示)</li>-->
<!--          <li><del>增加访问量统计</del></li>-->
<!--        </ul>-->
      </div>
      <div class="column is-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
}
</script>

<style scoped>
li {
  margin-top: 10px;
  list-style-type: disc;
}
</style>
